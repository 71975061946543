import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const pt_BR = require("./translations/pt-BR/locale.json");
const en = require("./translations/en/locale.json");
const zh_Hans = require("./translations/zh-Hans/locale.json");
const resources = {
  "pt-BR": {
    translation: pt_BR,
  },
  "en": {
    translation: en,
  },
  "zh-Hans": {
    translation: zh_Hans,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pt-BR",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

i18n.on("languageChanged", (lang) => {
  localStorage.setItem("lang", lang);
  document.documentElement.lang = lang;
});
let lang = localStorage.getItem("lang");
lang = lang ? lang : "pt-BR";
i18n.changeLanguage(lang)

export default i18n;
